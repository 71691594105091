import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class BioconnectService {
  // api url
  private api: string = environment.securedURLs.sip;
  private bioconnect: string = environment.securedURLs.bioconnect;

  // study assays behavioursubject for building an observable
  public studyAssays = new BehaviorSubject<any>({});
  // observable, which can be subscribed to in order to get changes to the linked studies list any time it is updated
  public studyAssays$ = this.studyAssays.asObservable();
  // list of study ids for which the request to cache assays has been made already
  private studyAssaysInitialized: any = {};

  // declare http client
  constructor(private http: HttpClient) {}

  /**
   * Gets a list of bioconnect study ids that are linked to MPD project
   *
   * @returns {Observable<any>} Observable
   */
  getLinkedStudies(): Observable<any> {
    return this.http.get<any>(this.api + 'bioconnect/linked_studies');
  }

  /**
   * Gets a list of assay for a study id and caches the result so we aren't hitting the bioconnect api over and
   * over for each instance of the assay lookup on the protocols page
   *
   * @param studyId: bioconnect study id for which we are getting a list of assays
   */
  getStudyAssays(studyId: number) {
    if (!this.studyAssaysInitialized[studyId]) {
      this.studyAssaysInitialized[studyId] = true;
      this.http.get(this.bioconnect + 'metadata_repository/study/' + studyId + '/details/').subscribe((result: any) => {
        const studyAssays = this.studyAssays.getValue();
        studyAssays[studyId] = result.assay_set.map((option: any) => {
          return { id: option.id, identifier: option.identifier };
        });
        this.studyAssays.next(studyAssays);
      });
    }
  }
}
