<mat-toolbar>
  <h2>Application Settings</h2>
</mat-toolbar>
<br />

<div *ngIf="!isCurator">
  <br />
  <h4>You do not have access to this page.</h4>
</div>

<div [style.display]="isCurator ? 'block' : 'none'">
  <mat-expansion-panel>
    <mat-expansion-panel-header>SIP Access Settings</mat-expansion-panel-header>

    <div align="center" *ngIf="loadingBar">
      <b>Loading...</b>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div [style.display]="!loadingBar ? 'block' : 'none'">
      <div *ngIf="errorMessage">
        <h4>{{ errorMessage }}</h4>
      </div>

      <div [style.display]="!errorMessage ? 'block' : 'none'">
        <div class="form-horizontal">
          <div class="form-group">
            <label style="padding-top: 0" class="col-md-2 col-sm-3 control-label" for="restricted_access">
              Only Allow Access To
            </label>
            <div class="col-sm-9">
              <mat-radio-group
                id="restricted_access"
                name="restricted_access"
                (change)="onRestrictedAccessChange()"
                [(ngModel)]="appSettings.restricted_access"
              >
                <mat-radio-button [value]="null"> Everyone </mat-radio-button>
                &nbsp;&nbsp;
                <mat-radio-button value="Whitelist"> Whitelist and Curators </mat-radio-button>
                &nbsp;&nbsp;
                <mat-radio-button value="Curator"> Curators Only </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="form-group">
            <label style="padding-top: 0" class="col-md-2 col-sm-3 control-label"> Curators </label>
            <div class="col-sm-9">
              <div *ngFor="let curator of appSettings.curators">
                <person-display [person]="curator" [showCuratorIcon]="true"></person-display>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-2 col-sm-3 control-label"> Whitelist </label>
            <div class="col-sm-9">
              <app-table
                [columns]="whitelistColumns"
                [rows]="whitelistRows"
                [defPageSize]="5"
                (actionClick)="addRemoveWhitelistEmail($event.row.email, 'true')"
              ></app-table>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-2 col-sm-3 control-label">Add to Whitelist</label>
            <div class="col-sm-6">
              <person-search
                #person_lookup
                [(person)]="personSearch"
                [user]="true"
                [inviteByEmail]="true"
                [showDialog]="true"
                (personChange)="whitelistLookupChange($event.email)"
                [disableList]="personDisableList"
              ></person-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <br />
  <mat-expansion-panel>
    <mat-expansion-panel-header>App File Uploads</mat-expansion-panel-header>
    <file-upload
      [allowEdit]="isCurator"
      [pointertype]="'app'"
      [title]="'Help Documents'"
      [pointer]="'1'"
      [tag]="'help'"
      [fileTypes]="'.pdf'"
      [optional]="false"
    ></file-upload>
    <br />
    <file-upload
      [allowEdit]="isCurator"
      [pointertype]="'app'"
      [title]="'FAQ Document'"
      [optional]="false"
      [pointer]="'1'"
      [tag]="'faq'"
      [fileTypes]="'.md'"
      [fileNumLimit]="1"
      [showDescription]="false"
    ></file-upload>
    <br />
    <file-upload
      [allowEdit]="isCurator"
      [pointertype]="'app'"
      [title]="'FAQ Images'"
      [optional]="false"
      [pointer]="'1'"
      [tag]="'faqimages'"
      [imageOnly]="true"
      [showFileLink]="true"
      [showDescription]="false"
    ></file-upload>
    <br />
  </mat-expansion-panel>
  <br />
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      Ontology Loading &nbsp;&nbsp;
      <span
        *ngIf="newerOntologyVersion || !measOntLinkActive"
        class="glyphicon glyphicon-warning-sign yellow"
        [matTooltip]="ontLoadAttentionTooltip()"
      ></span>
      <span
        *ngIf="!(newerOntologyVersion || !measOntLinkActive)"
        class="glyphicon glyphicon-ok-circle green"
        matTooltip="Ontologies are up-to-date."
      ></span>
    </mat-expansion-panel-header>

    <div align="center" *ngIf="ontLoadingBar">
      <b>Loading...</b>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div [style.display]="!ontLoadingBar ? 'block' : 'none'">
      <div *ngIf="ontErrorMessage">
        <h4>{{ ontErrorMessage }}</h4>
      </div>

      <div [style.display]="!ontErrorMessage ? 'block' : 'none'">
        <a (click)="ontLoadAction('check_for_updates')">Check for Updates</a>
        <br /><br />
        <a (click)="viewOntLog()">View Log</a>
        <br /><br />
        <a (click)="ontLoadAction('count')">Update Ontology Counts</a>
        <span *ngIf="!measOntLinkActive">
          <br /><br />
          <a (click)="ontLoadAction('measlink')">Re-establish measure links</a>
          &nbsp;&nbsp;
          <span
            class="glyphicon glyphicon-warning-sign yellow"
            matTooltip="Measure links are currently inactive"
          ></span>
        </span>
        <br /><br />
        <a (click)="ontLoadAction('load', 'MA')">Load Latest Adult Mouse Anatomy (MA) Ontology Version (from MGI)</a>
        &nbsp;&nbsp;
        <span
          *ngIf="newerMaVersion"
          class="glyphicon glyphicon-warning-sign yellow"
          [matTooltip]="newerMaVersion"
        ></span>
        <span
          *ngIf="!newerMaVersion"
          class="glyphicon glyphicon-ok-circle green"
          [matTooltip]="'The Adult Mouse Anatomy (MA) Ontology is up-to-date, version: ' + latestMaVersion"
        ></span>
        <br /><br />
        <a (click)="ontLoadAction('load', 'MP')">Load Latest Mammalian Phenotype (MP) Ontology Version (from MGI)</a>
        &nbsp;&nbsp;
        <span
          *ngIf="newerMpVersion"
          class="glyphicon glyphicon-warning-sign yellow"
          [matTooltip]="newerMpVersion"
        ></span>
        <span
          *ngIf="!newerMpVersion"
          class="glyphicon glyphicon-ok-circle green"
          [matTooltip]="'The Mammalian Phenotype (MP) Ontology is up-to-date, version: ' + latestMpVersion"
        ></span>
        <br /><br />
        <a (click)="ontLoadAction('load', 'VT')">Load Latest Vertebrate Trait (VT) Ontology (from bioontology)</a>
        &nbsp;&nbsp;
        <span
          *ngIf="newerVtVersion"
          class="glyphicon glyphicon-warning-sign yellow"
          [matTooltip]="newerVtVersion"
        ></span>
        <span
          *ngIf="!newerVtVersion"
          class="glyphicon glyphicon-ok-circle green"
          [matTooltip]="'The Vertebrate Trait (VT) Ontology is up-to-date, version: ' + latestVtVersion"
        ></span>
        <!--TODO: remove these later on when no longer needed for testing?-->
        <div *ngIf="!production">
          <br /><br />
          TESTING ONTOLOGIES BEING ADDED, REMOVED, OR DESCRIPTIONS CHANGED:
          <br /><br />
          <a (click)="ontLoadAction('load', 'VT', undefined, 90)"
            >Load Older Vertebrate Trait (VT) Ontology Version (11.1)</a
          >
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <br />
  <mat-expansion-panel>
    <mat-expansion-panel-header>Export Data to BioConnect</mat-expansion-panel-header>
      <button type="button"
        [disabled]="etlWorkflowRunning || etlWorkflowCheckError"
        class="btn btn-primary"
        (click)="startEtlWorkflow()"
      >
        Start Export from SIP to BioConnect
      </button>
    &nbsp;&nbsp;
    <b>Status: </b>{{ etlWorkflowStatusText() }}
    &nbsp;&nbsp;
    <b># Studies to Export: </b>{{ unlinkedStudies }}
    &nbsp;&nbsp;
    <b># Linked Studies: </b>{{ linkedStudies }}
    <div align="center" *ngIf="etlWorkflowRunning">
      <br />
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <br /><br />
    <div *ngIf="this.bcEtlWorkflows.length > 0">Previous/Current Run Status
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Start Time</th>
            <th>Status</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let workflow of bcEtlWorkflows">
            <tr>
              <td>{{ workflow.start_time }}</td>
              <td>{{ workflow.status }}</td>
              <td>
                {{ getWorkflowResultSummary(workflow) }}
                <small *ngIf="workflow.result">
                  <a (click)="workflow.showing = !workflow.showing" style="cursor: pointer;">
                    <mat-icon>{{ workflow.showing ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                  </a>
                </small>
              </td>
            </tr>
            <tr *ngIf="workflow.showing">
              <td colspan="1">
                <button type="button" class="btn btn-danger" (click)="workflow.showing = !workflow.showing">X</button>
              </td>
              <td colspan="20"><pre *ngIf="workflow.result" [innerHtml]="getWorkflowResultDisplay(workflow)"></pre></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <br />
  </mat-expansion-panel>
</div>
