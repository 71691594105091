import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class TemporalService {
  // api url
  private api: string = environment.securedURLs.sip;

  // declare http client
  constructor(private http: HttpClient) {}

  /**
   * Get a list of workflow statuses for either a specific workflow id, or a workflow type
   *
   * @param {string} id: workflow id we want that status of
   * @param {string} type: workflow type was want status(es) for
   * @returns {Observable<any>}: {"workflows": [list of objects with workflow statuses and results (if done)],
                                  "other_data": depends on the type... for "etl_sip_to_bioconnect",
                                                returning # of SIP projects that have and do not have
                                                linked bioconnect studies}
   */
  getWorkflowStatus(id: string = '', type: string = ''): Observable<any> {
    let url = this.api + 'temporal';
    if (id) {
      url += '?id=' + String(id);
    } else if (type) {
      url += '?type=' + String(type);
    }
    return this.http.get<any[]>(url);
  }

  /**
   * Observable for starting a temporal workflow
   *
   * @param {string} type: workflow type was want status(es) for
   * @param {any} arg: (optional) argument(s) for the workflow
   * @returns {Observable<any>}:
   */
  startTemporalWorkflow(type: string = '', arg: any = null): Observable<any> {
    const url = this.api + 'temporal';
    return this.http.post<any>(url, { type: type, arg: arg }, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }
}
