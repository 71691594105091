import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PersonService } from '../../services/person.service';
import { environment } from '../../../environments/environment';
import { PersonSearchComponent } from '../../shared/entity-searching/person-search.component';
import { OntologyService } from '../../services/ontology.service';
import { TemporalService } from '../../services/temporal.service';
import { MatDialog } from '@angular/material/dialog';
import { LogViewerDialogComponent } from '../../shared/dialogs/log-viewer-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog.component';
import { escapeHtml } from '../../shared/utils';

@Component({
  selector: 'app-settings',
  templateUrl: 'admin.component.html',
  styleUrls: ['admin.component.scss'],
})
export class AdminComponent implements OnInit {
  // variable for accessing the whitelist search
  @ViewChild(PersonSearchComponent) personSearchComponent!: PersonSearchComponent;

  // flag indicating whether the current user is a curator
  isCurator = false;

  production: boolean = environment.production;

  // app settings object
  appSettings: any = { access_whitelist: [], restricted_access: null, curators: [] };

  // true while projects are being loaded from the api
  // (shows a loading bar and disables filters while we are loading)
  loadingBar = true;
  // if loading fails...
  errorMessage = '';

  // declaring variables, which are used to build the tables
  whitelistRows: any[] = [];
  whitelistColumns = [
    { key: 'email', label: 'Email', width: '5' },
    { key: 'name', label: 'Name', width: '5' },
    { key: 'user', label: 'User?', width: '1' },
    {
      key: 'remove',
      label: 'Remove',
      width: '1',
      click: 'remove',
      type: 'button',
      classes: 'btn btn-danger',
      safe: true,
      value: '<span class="glyphicon glyphicon-remove"></span>',
    },
  ];

  // object for the profile selected in the whitelist lookup
  personSearch: any = {};

  // list of person objects to disable in the whitelist lookup
  personDisableList: any[] = [];

  // ontology loading variables
  newerMaVersion = '';
  newerVtVersion = '';
  newerMpVersion = '';
  latestMaVersion = 'Unknown';
  latestVtVersion = 'Unknown';
  latestMpVersion = 'Unknown';
  newerOntologyVersion = false;
  measOntLinkActive = true;
  ontVersions: any = {};
  ontLogs: any[] = [];
  ontLoadingBar = true;
  ontErrorMessage = '';

  bcEtlWorkflows: any[] = [];
  etlWorkflowRunning = true;
  anyEtlWorkflowCompleted = false;
  etlWorkflowCheckError = false;
  linkedStudies = 0;
  unlinkedStudies = 0;

  constructor(
    private personService: PersonService,
    private ontologyService: OntologyService,
    private temporalService: TemporalService,
    public dialog: MatDialog,
    private http: HttpClient,
  ) {}

  // on load determine whether the current user is a curator and load the app settings
  ngOnInit() {
    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
    this.getEtlWorkflowStatus();
    this.loadAppSettings();
    this.getOntLoadData();
  }

  // load the app settings
  loadAppSettings() {
    this.http.get<any>(environment.securedURLs.sip + 'appsettings').subscribe(
      (result) => {
        this.appSettings = result;
        this.buildSettingsVars();
        this.loadingBar = false;
      },
      () => {
        this.errorMessage = 'Error loading app settings';
        this.loadingBar = false;
      },
    );
  }

  // get the status of the etl workflow (cthere an be more than 1 attempt)
  getEtlWorkflowStatus() {
    this.temporalService.getWorkflowStatus('', 'etl_sip_to_bioconnect').subscribe(result => {
      this.bcEtlWorkflows = result.workflows;
      this.unlinkedStudies = result.other_data.unlinked_count;
      this.linkedStudies = result.other_data.linked_count;
      if (this.bcEtlWorkflows.filter(value => { return value.status === 'COMPLETED'; }).length > 0) {
        this.anyEtlWorkflowCompleted = true;
      }
      if (this.bcEtlWorkflows.filter(value => { return value.status === 'RUNNING'; }).length > 0) {
        // check again in 60 seconds to see if the running workflow has completed and to update study counts
        this.etlWorkflowRunning = true;
          setTimeout(() => {
          this.getEtlWorkflowStatus();
        }, 60000);
      } else {
        this.etlWorkflowRunning = false;
      }
    }, error => {
      this.etlWorkflowRunning = false;
      this.etlWorkflowCheckError = true;
    });
  }

  // start a new run of the sip -> bc etl if one isn't already running
  startEtlWorkflow() {
    if (!this.etlWorkflowRunning && !this.etlWorkflowCheckError) {
      this.etlWorkflowRunning = true;
      this.temporalService.startTemporalWorkflow('etl_sip_to_bioconnect').subscribe(result => {
        this.getEtlWorkflowStatus();
      });
    }
  }

  // get an overall status to show
  etlWorkflowStatusText(): string {
    if (this.etlWorkflowCheckError) {
      return 'Unknown (error getting status)';
    } else if (this.etlWorkflowRunning) {
      return 'Running';
    } else if (this.bcEtlWorkflows.length === 0) {
      return 'Not Started';
    } else if (this.anyEtlWorkflowCompleted) {
      return 'Completed';
    } else {
      return 'Failed';
    }
  }

  /**
   * Show a summary of the number of successful and failed projects exports
   * @param {object}: workflow status object
   */
  getWorkflowResultSummary(workflow: any): string {
    if (workflow.result) {
      return `Project Exports ... Success Count: ${workflow.result.success_count} ...
        Error Count: ${workflow.result.error_count}`;
    }
    return '';
  }

  /**
   * Show the detailed json return value detailing each successful and failed project export
   * @param {object}: workflow status object
   */
  getWorkflowResultDisplay(workflow: any): string {
    return escapeHtml(JSON.stringify(workflow.result, undefined, 2));
  }

  // on change of the restricted access setting, update on the backend
  onRestrictedAccessChange() {
    this.http
      .put<any>(
        environment.securedURLs.sip + 'appsettings',
        { restricted_access: this.appSettings.restricted_access },
        { headers: new HttpHeaders().set('Content-Type', 'application/json') },
      )
      .subscribe(
        (result) => {
          this.appSettings = result;
          this.buildSettingsVars();
        },
        () => {
          this.errorMessage = 'Error saving app settings';
        },
      );
  }

  /**
   * Function for updating the access whitelist in the backend
   * @param {string} email: email we are adding or removing
   * @param {string} remove: 'true' to remove the email, null to add it
   */
  addRemoveWhitelistEmail(email: string, remove: string | null = null) {
    if (email) {
      this.http
        .put<any>(
          environment.securedURLs.sip + 'appsettings',
          { access_whitelist: { email: email, remove: remove } },
          { headers: new HttpHeaders().set('Content-Type', 'application/json') },
        )
        .subscribe(
          (result) => {
            this.appSettings = result;
            this.buildSettingsVars();
          },
          () => {
            this.errorMessage = 'Error saving app settings';
          },
        );
    }
  }

  // builds variables used to represent settings in the front end
  buildSettingsVars() {
    this.buildWhitelistRows();
    this.buildWhitelistDiasblelist();
  }

  // builds the rows for the whitelist in the mat-table
  buildWhitelistRows() {
    this.whitelistRows = this.appSettings.access_whitelist.map(function (row: any) {
      return {
        email: row.email,
        name: row.person ? row.person.fullname : '',
        user: row.person ? (row.person.isuser ? 'Yes' : 'No') : 'No',
      };
    });
  }

  // builds the list of person objects to disable in the whitelist lookup
  buildWhitelistDiasblelist() {
    this.personDisableList = [];
    for (const row of this.appSettings.access_whitelist) {
      if (row.person) {
        this.personDisableList.push(row.person);
      }
    }
  }

  /**
   * On change of the whitelist lookup, adds the email to the list and clears the lookup
   * @param {string} email: email selected in the lookup
   * @param {string} email: email selected in the lookup
   */
  whitelistLookupChange(email: string) {
    if (email) {
      this.addRemoveWhitelistEmail(email);
      this.personSearchComponent.clearSelection();
    }
  }

  // on load of the page, get the ontology loading versions and logs
  getOntLoadData() {
    this.ontologyService.ontLoadGet().subscribe(
      (result) => {
        this.buildOntLoadVars(result);
        this.ontLoadingBar = false;
      },
      () => {
        this.ontErrorMessage = 'Error ontology loading information';
        this.ontLoadingBar = false;
      },
    );
  }

  /**
   * Sets several variables for the ontology loading interface that are used to
   * store logs and versions, flag whether new versions are needed for any
   * ontology types, and flag whether measure links need to be re-established
   *
   * @param {any} result: returned object from ontload endpoint (contains versions and logs)
   */
  buildOntLoadVars(result: any) {
    this.ontVersions = result.versions;
    this.ontLogs = result.logs;
    this.measOntLinkActive = result.versions.MEASLINK.version === 'Active';
    this.newerMaVersion = '';
    this.newerVtVersion = '';
    this.newerMpVersion = '';
    this.latestMaVersion = 'Unknown';
    this.latestVtVersion = 'Unknown';
    this.latestMpVersion = 'Unknown';

    if (result.versions.MA.length) {
      const latestVersion = result.versions.MA[0];
      this.latestMaVersion = latestVersion.version;
      if (!latestVersion.loaded_datetime) {
        let currentVersion = null;
        for (const version of result.versions.MA) {
          if (version.loaded_datetime) {
            currentVersion = version;
            break;
          }
        }
        this.newerMaVersion =
          'New version of Adult Mouse Anatomy Ontology (MA) available: ' +
          latestVersion.version +
          (currentVersion ? ' (current version: ' + currentVersion.version + ')' : '');
      }
    }
    if (result.versions.MP.length) {
      const latestVersion = result.versions.MP[0];
      this.latestMpVersion = latestVersion.version;
      if (!latestVersion.loaded_datetime) {
        let currentVersion = null;
        for (const version of result.versions.MP) {
          if (version.loaded_datetime) {
            currentVersion = version;
            break;
          }
        }
        this.newerMpVersion =
          'New version of Mammalian Phenotype Ontology (MP) available: ' +
          latestVersion.version +
          (currentVersion ? ' (current version: ' + currentVersion.version + ')' : '');
      }
    }
    if (result.versions.VT.length) {
      const latestVersion = result.versions.VT[0];
      this.latestVtVersion = latestVersion.version;
      if (!latestVersion.loaded_datetime) {
        let currentVersion = null;
        for (const version of result.versions.VT) {
          if (version.loaded_datetime) {
            currentVersion = version;
            break;
          }
        }
        this.newerVtVersion =
          'New version of Vertebrate Trait Ontology (VT) available: ' +
          latestVersion.version +
          (currentVersion ? ' (current version: ' + currentVersion.version + ')' : '');
      }
    }
    this.newerOntologyVersion = Boolean(this.newerMpVersion || this.newerMaVersion || this.newerVtVersion);
  }

  /**
   * Builds the string containing message to show on the warning tooltip if
   * action is needed in the ontology loading section
   *
   * @returns {string}: String containing message to show on the warning tooltip if
   *                    action is needed in the ontology loading section
   */
  ontLoadAttentionTooltip(): string {
    const tooltip: string[] = [];
    if (this.newerOntologyVersion) {
      tooltip.push('New ontology version(s) are available');
    }
    if (!this.measOntLinkActive) {
      tooltip.push('Measure links need to be re-established');
    }
    return tooltip.join(' and ');
  }

  /**
   * Function to run any of the actions in the ontology loading options, depending on arguments
   *
   * @param {string} action: ontology loading action to execute...
   *                         "load" to load ontologies,
   *                         "measlink" to attempt to re-establish measure links (also checks which links are orphaned
   *                             and adds the list to the log when it fails),
   *                         "count" to re-count # of measures associated with ontologies,
   *                         "check_for_updates" to check for updates from bioontology
   * @param {string} ontType: (required if action === "load" and loading from bioontology) "MA", "MP", or "VT"
   * @param {string} fileId: (either this or ont_type required if action === "load") file id to load the ontologies from
   * @param {number} submissionId: (optional if action === "load" and loading from bioontology (ont_type is set))
   *                               submission id to load from bioontology... if left blank, we will just get the
   *                               latest for the ont_type
   */
  ontLoadAction(action: string, ontType: string = '', fileId: string = '', submissionId: number | null = null) {
    this.ontLoadingBar = true;
    this.ontologyService.ontLoadPost(action, ontType, fileId, submissionId).subscribe(
      (result) => {
        this.buildOntLoadVars(result);
        this.ontLoadingBar = false;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            header: result.error ? 'Error' : 'Success',
            message: result.message,
            hidefalsebtn: true,
            truelabel: 'Close',
            truebtn: 'btn-default',
          },
        });
        dialogRef.afterClosed().subscribe();
      },
      () => {
        this.ontLoadingBar = false;
      },
    );
  }

  // show a dialog with the history of what occurred during ontology loading, re-doing ontology counts,
  // or re-establishing ontology measure links
  viewOntLog() {
    const entries = JSON.parse(JSON.stringify(this.ontLogs));
    const dialogRef = this.dialog.open(LogViewerDialogComponent, {
      data: {
        header: 'Ontology Loading Logs',
        entries: entries,
        logtype: 'ontload',
      },
    });
    dialogRef.afterClosed().subscribe();
  }
}
