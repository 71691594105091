import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { GroupService } from '../../services/group.service';
import { PersonService } from '../../services/person.service';
import { ProjectService } from '../../services/project.service';
import { BioconnectStudySearchComponent } from '../../shared/entity-searching/bioconnect-study-search.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'import-project',
  templateUrl: './import-project.component.html',
  styleUrls: ['./import-project.component.scss'],
})
export class ImportProjectComponent implements OnInit {
  // variable for accessing the bioconnect study search
  @ViewChild(BioconnectStudySearchComponent) bioconnectStudySearchComponent!: BioconnectStudySearchComponent;

  // groups, which this user is a member of, associated with importing platforms
  groups: any[] = [];

  // becomes true for the time between request start and response completion
  isInProgress = false;
  // becomes true when user clicks the cancel button during an import process
  isCancelled = false;

  alreadyExists = false;

  // the id of the entity (record) on the remote platform,
  // the data of which will be imported into SIP
  requestId = '';

  // selected bioconnect study
  selectedStudy: any = {};

  // object storing response data:
  // id: string - newly imported projects in SIP get saved in the database with unique id
  // message: message provided by the request handler
  // notices: string[] - will be passed to/displayed on the QC dashboard component
  /* response: {
    id: string,
    message: string,
    notices: string[]
  }; */
  response: any = {};

  // SIP project id (once the request has been imported)
  projectId: number | null = null;

  // importing status message(s)
  status: any = {
    cancelled: false,
    completed: false,
    importing: false,
    message: false,
  };

  // bioconnect urls... used to determine whether to show bioconnect import
  bioconnect = environment.securedURLs.bioconnect;
  bioconnectUi = environment.unsecuredURLs.bioconnectUi;

  // object storing the values of the selected group opition
  selected: any = {};
  // selectedGroupName: string = '';
  // selectedGroupResource: string = '';
  // selectedGroupId: string = '';

  // CLASS METHODS
  constructor(
    private router: Router,
    private groupService: GroupService,
    private personService: PersonService,
    private projectService: ProjectService,
  ) {}

  ngOnInit() {
    this.getUserGroups();
  }

  /**
   * Updates the message board depending on the status of the request: completed, aborted, ..
   * @param: {string} val - value describing the status of the request
   */
  setStatus(val: string): void {
    Object.keys(this.status).forEach((k) => (this.status[k] = k === val));
  }

  /**
   * returns true when all necessary inputs to initate the import have been filled;
   * both the group and the project ID have to be provided to initate the import.
   */
  canImport(): boolean {
    // trim white spaces
    const id = (this.requestId || '').trim();
    const res = !!(this.selected.id && id);

    return res;
  }

  /**
   * Gets the groups (if any) list for the current user, iterates through and
   * picks those groups who are in the STATIC_IMPORT_ENABLED_GROUPS structure.
   */
  getUserGroups() {
    // TODO [gik 01/22/20]: this static structure should be removed in the future
    // once the necessary database updates are available; group - importing platform associations
    // should be defined in the database and this is just the placeholder for that.
    const STATIC_IMPORT_ENABLED_GROUPS: { id: string; group: string; service: string; resource: string }[] = [
      {
        id: '1',
        group: 'Center for Biometrics Analysis Techs',
        service: 'CBA',
        resource: 'JAX CBA - Platform for Science',
      },
      {
        id: '2',
        group: 'BioConnect',
        service: 'BioConnect',
        resource: 'BioConnect API',
      },
    ];

    this.groupService.getGroup('', 'current').subscribe((data) => {
      this.groups = STATIC_IMPORT_ENABLED_GROUPS.filter((o) => {
        if (o.group === 'BioConnect' && this.bioconnect && this.bioconnectUi) {
          return true;
        }
        if (data.map((d: any) => d.name).includes(o.group)) {
          return true;
        }
      });

      this.groups.sort((a, b) => (a.name > b.name ? 1 : -1));

      if (this.groups.length) {
        this.onGroupChange(this.groups[0].id);
      }
    });
  }

  /**
   * captures the CANCEL button click event and updates the associated component properties -
   * the 'cancellation event' actually waits for the project to be imported completely and
   * then deletes/removes it from the (SIP) database.
   */
  cancelImport(): void {
    this.isCancelled = true;
  }

  /**
   * calls the service responsible for initiating the importing process and handles
   * the received response - that could either be a successful payload or some error exception.
   */
  initiateImport(): void {
    this.isInProgress = true;
    this.status.message = false;
    this.status.cancelled = false;

    if (this.selected.service == 'CBA') {
      // check whether this project has already been imported; if it has been, just show
      // a message and a link to the project details page(, which provides a RELOAD button)
      this.projectService.getProject(this.requestId, true).subscribe(
        (data) => {
          this.isInProgress = false;
          this.alreadyExists = true;
          this.projectId = data.projid;
        },
        (e) => {
          // status 400 BAD REQUEST means that no project with
          // this requestId exists, so proceed to import it.
          if (e.status === 400) {
            this.initiateImportAfterCheck();
          } else {
            this.isInProgress = false;
            this.response.message = e;
            this.setStatus('message');
          }
        },
      );
    } else {
      this.initiateImportAfterCheck();
    }
  }

  initiateImportAfterCheck(): void {
    this.projectService.importRequest(this.selected.service, this.requestId).subscribe(
      (data) => {
        this.projectId = data.id;

        this.response.id = data.id;
        this.response.message = data.message;
        this.response.notices = data.notices;
      },
      (e) => {
        this.isInProgress = false;
        this.response.message = e;
        this.setStatus('message');
      },
      () => {
        // this block is executed always
        setTimeout(() => {
          this.isInProgress = false;

          // if user has clicked the Cancel button while the import is in progress,
          // DELETE the just imported record from the SIP database.
          if (this.isCancelled) {
            const id = Number(this.response.id) || 0;
            if (id) {
              this.projectService.deleteProject(Number(this.response.id)).subscribe(() => {
                this.setStatus('cancelled');
              });
            }
          } else {
            // this handles cases when there is data from the remote platform, but it is not enough
            // to create a new project in SIP: the response returns SIP ID as 'null' or 'undefined' and a message.
            // use double '==' to check both 'none' and 'undefined'
            if (this.response.id == null) {
              this.setStatus('message');
            } else {
              this.setStatus('completed');
              // intentianally delayed redirection so user have time read the
              // message that importing has successfully completed
              setTimeout(() => {
                this.router.navigate([
                  this.selected.service == 'BioConnect' ? '/proj-details' : '/definitions',
                  this.response.id,
                ]);
              }, 5000);
            }
          }
        }, 10000);
      },
    );
  }

  onStudyChange() {
    this.requestId = this.selectedStudy ? (this.selectedStudy.id ? String(this.selectedStudy.id) : '') : '';
  }

  /**
   * captures change events in the 'Group' select and updates the
   * associated component property.
   */
  onGroupChange(value: string): void {
    this.requestId = '';
    if (this.bioconnectStudySearchComponent) {
      this.bioconnectStudySearchComponent.clearSelection();
    }
    this.selected.id = value;

    const selectedGroup = this.groups.filter((g) => g.id === value);

    this.selected.group = selectedGroup[0].group;
    this.selected.service = selectedGroup[0].service;
    this.selected.resource = selectedGroup[0].resource;
  }
}
