import { Component, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { FlashMessageComponent } from '../flash-message.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog.component';
import { DivDBService } from '../../services/divdb.service';
import { combineLatest } from 'rxjs';
import { ProjectImportExportDialogComponent } from '../dialogs/project-import-export.component';

@Component({
  selector: 'project-actions',
  templateUrl: './project-actions.component.html',
  styleUrls: ['./project-actions.component.scss'],
})
export class ProjectActionsComponent implements OnChanges {
  // project object for which we are rendering actions
  @Input() project: any = {};

  // flag to not show the view or edit action (because we are already in it)
  @Input() noViewOrEdit = false;

  // flag to not show the sharing action (because we are already in it)
  @Input() noSharing = false;

  // flag to allow the import and export dialog to be triggered here
  @Input() allowTriggerImportExport = true;

  // event that emits to let the parent component know that the project was deleted
  @Output() projectDeleted: EventEmitter<any> = new EventEmitter<any>();

  // event that emits to let the parent component know that the project was restored
  @Output() projectRestored: EventEmitter<any> = new EventEmitter<any>();

  // event that emits to let the parent know the project changed (currently due to import)
  @Output() projectChanged: EventEmitter<any> = new EventEmitter<any>();

  // Event that is emitted when the an external link is clicked (right now just the preview link).
  // The link to be gone to is sent in the event, and if the parent handles any saving required before going to it.
  @Output() clickExternalLink: EventEmitter<any> = new EventEmitter<any>();

  // emitted to let parent handle if we're not allowing import & export to be triggered here...
  @Output() clickImportExport: EventEmitter<any> = new EventEmitter<any>();

  // array of mat-icon names that represent actions that are available
  actionIcons: string[] = [];

  // url for previewing data
  previewURL = environment.securedURLs.mpdPreview;

  links = {
    edit: '/proj-details/',
    visibility: '/proj-details/',
    folder_shared: '/share-project/',
    person_add: '/share-project/',
  };

  tooltips = {
    edit: 'View/Edit this project',
    visibility: 'View this project',
    import_export: 'Import or Export this project',
    delete: 'Delete this project',
    delete_forever: 'Delete this project (permanently)',
    folder_shared: 'View sharing for this project',
    person_add: 'Share this project',
    restore_from_trash: 'Restore this project',
  };

  // bioconnect urls... used to determine whether to show bioconnect import
  bioconnect = environment.securedURLs.bioconnect;
  bioconnectUi = environment.unsecuredURLs.bioconnectUi;

  constructor(
    private projectService: ProjectService,
    private divdb: DivDBService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  // on change, determine which actions should be available based on the status of the project and other inputs
  // and add those actions to the actionIcons array
  // NOTE: A better way (in my opinion) to do this would probably just be to get rid of the actionIcons, links
  //       tooltips variables altogether and just handle all of the if contions in the html using *ngIf, but doing
  //       it this way works for now.
  ngOnChanges() {
    this.actionIcons = [];

    // view/edit action
    if (this.project.current_user_permission && !this.noViewOrEdit) {
      if (this.project.canEdit) {
        this.actionIcons.push('edit');
      } else {
        this.actionIcons.push('visibility');
      }
    }

    // import or export the project action
    if (this.project.canEdit && this.bioconnect && this.bioconnectUi) {
      this.actionIcons.push('import_export');
    }

    // sharing/view sharing action
    if ((this.project.shared_entities_exist || this.project.canEdit) && !this.noSharing) {
      if (this.project.canEdit) {
        this.actionIcons.push('person_add');
      } else {
        this.actionIcons.push('folder_shared');
      }
    }

    // view/preview data action
    if (this.project.current_user_permission && !this.project.deletedtime) {
      this.actionIcons.push('bar_chart');
    }

    // delete action
    if (this.project.canDelete && !this.project.deletedtime) {
      this.actionIcons.push('delete');
    }

    // restore and delete permanently actions
    if (this.project.deletedtime && this.project.isOwner) {
      this.actionIcons.push('restore_from_trash');
      if (this.project.isCurator) {
        this.actionIcons.push('delete_forever');
      }
    }
  }

  /**
   * delete this project (only Owners can do this)
   * @param {boolean} permanent: true to delete the project permanently (only curators can do this currently)
   */
  deleteProject(permanent: boolean = false) {
    if (this.project.canDelete) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Confirm Delete Project' + (permanent ? ' Permanently' : ''),
          message:
            'Are you sure that you wish to delete the project' +
            (permanent ? ' permanently' : '') +
            ',<br><b>' +
            this.project.projsym +
            ': ' +
            this.project.title +
            '</b>?<br><br>' +
            (permanent
              ? ''
              : 'The project will be archived for ' +
                environment.projectArchiveDays +
                ' days, during which time it can be recovered by a project owner or a SIP curator.<br> ' +
                'However, after that it will be deleted permanently. <br><br>' +
                '<span style="color: #c00">Warning: If the project has any genotype datasets, these ' +
                'datasets will be deleted.</span>'),
          falselabel: 'Cancel',
          truelabel: 'Delete',
          truebtn: 'btn-danger',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          // if there are genotype datasets with this project, delete them first
          // TODO: there's a possible case down the road when we might need to associate a dataset
          //  with multiple projects; when we need to support this, we'll need a way of checking if
          //  it's safe to delete and if it's not, just deleting from the SIP side would is needed
          if (this.project.genotypes.length) {
            combineLatest(
              this.project.genotypes.map((g: any) => this.divdb.deleteDataset(g.genotype_dataset_id)),
            ).subscribe(
              () => console.log('Datasets for this project deleted'),
              (error) => {
                console.log(error);
              },
            );
          }

          this.projectService.deleteProject(this.project.projid, permanent).subscribe(() => {
            // Let the parent component know that the project was deleted.
            // It will have to figure out what to do next
            this.projectDeleted.emit(this.project.projid);
          });
        }
      });
    }
  }

  // delete this project (only Owners can do this)
  restoreProject() {
    if (this.project.isOwner && this.project.deletedtime) {
      this.projectService.saveProject({ projid: this.project.projid, deletedtime: null }).subscribe(
        () => {
          // Let the parent component know that the project was restored.
          // It will have to figure out what to do next
          this.projectRestored.emit(this.project.projid);
          this.flashMessage('Project successfully restored', 'alert-success');
        },
        (error2) => {
          let messages = ['Unknown error'];
          if (error2.error ? error2.error.message : false) {
            messages = [error2.error.message];
          }
          this.flashMessage('Failed to restore project:', 'alert-danger', 20000, messages);
        },
      );
    }
  }

  // handling of clicking of the import and export dialog...
  // either open directly, or emit an event to let the parent handle it
  onClickImportExport() {
    if (this.project.canEdit) {
      if (this.allowTriggerImportExport) {
        this.openProjectImportExport();
      } else {
        this.clickImportExport.emit(true);
      }
    }
  }

  // opens the project import and export dialog
  openProjectImportExport() {
    if (this.project.canEdit) {
      const dialogRef = this.dialog.open(ProjectImportExportDialogComponent, {
        data: {
          project: this.project,
        },
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result.change) {
          this.projectChanged.emit(result.project);
        }
      });
    }
  }

  /**
   * Use the "snackbar" to flash the passed-in message for the passed-in duration in
   * this passed-in class (panel).
   *
   * @param {string} message: message to show
   * @param {string} divClass: panel class to display the message in
   * @param {number} duration: duration to show the message
   * @param {string[]} warnings: list of warning strings to show
   */
  flashMessage(message: string, divClass: string = '', duration: number = 2000, warnings: string[] = []) {
    this.snackBar.openFromComponent(FlashMessageComponent, {
      duration: duration,
      data: {
        text: message,
        class: divClass,
        snackbar: this.snackBar,
        listcolor: '#CCCC00',
        listmessages: warnings,
      },
    });
  }
}
